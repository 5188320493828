export default {
    services: [
        {
            icon: "business",
            title: "Corretaje",
            content: "Estas buscando tu próxima vivienda? Comprar? Arrendar? Vender? Nosotros te apoyamos durante todo el proceso."
        },{
            icon: "how_to_reg",
            title: "Revisión de Antecedentes",
            content: "Nos ecargamos de la solicitud, revisión y aprobación de los antecedetes necesarios. Ya sean para una compra-venta o para un arrendamiento."
        },{
            icon: "account_circle",
            title: "Portal de Clientes",
            content: "Todos nuestros clientes tienes acceso a un portal privado donde pueden gestionar sus productos, estados y pagos."
        },{
            icon: "account_balance",
            title: "Gestión Inmobiliaria",
            content: "Cansado de preocuparte de tus propiedades? No esperes más, hemos llegado para hacernos cargo. Dependiendo del servicio contratado ya no tendrás que encargarte de nada más!"
        },{
            icon: "wc",
            title: "Consultoría",
            content: "Al igual que nosotros sabes lo ventajoso que es el mercado inmobiliario pero no sabes como sacarle provecho? Lee nuestro blog! Si aun tienes dudas y quieres ayuda profesional no dudes en contactarnos."
        },{
            icon: "build",
            title: "Inspección y Mantención",
            content: "Toda propiedad usada y nueva debe ser inspeccionada. Quieres asegurarte que la tuya esta en buen estado? Nosotros la revisamos. Mas aún, nos encargamos de los arreglos necesarios también."
        }
    ]
};