import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    boxShadow: "None",
    borderRadius: 0,
  },
  card: {
    backgroundColor: "inherit",
    paddingLeft: "20%",
    paddingRight: "20%",
    boxShadow: "None",
    textAlign: "center"
  },
});

class Services extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <footer>
        <Paper className={classes.container}>
          <Grid container justify="space-around">
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="inherit">
                    Copyright © Rour.dev - 2019
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="inherit">hola@rour.company</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </footer>
    );
  }
}

Services.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Services);
