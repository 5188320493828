import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import ButtonAppBar from "./Components/ButtonAppBar";
import Services from "./Components/Services";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";

import BackgroundImage from "./static/img/real_estate.jpg";
import StrokeImage from "./static/img/primary-brushstroke.png";

import services from "./Data/services";

const styles = theme => ({
  header: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white", 
    textAlign: "center",
  },
  highlight: {
    background: `url(${StrokeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 95%",
    padding: "8px 0",
  }
});

class App extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <ButtonAppBar />
        <header className={classes.header}>
        </header>
        <Services data={services.services} />
        <Contact />
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
