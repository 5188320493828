import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";

import SnackBar from "./SnackBar";

const styles = theme => ({
  container: {
    padding: "20px",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    textAlign: "center",
    color: "white",
    paddingTop: "10px"
  },
  textField: {
    marginLeft: 20,
    marginRight: 20,
  },
  inputLabelRoot: {
    color: "white",
  },
  inputLabelFocused: {
    color: "white !important",
  },
  inputRoot: {
    "&$cssFocused $notchedOutline": {
      borderColor: "white !important"
    },
    color: "white",
  },
  inputFocused: {
  },
  inputNotchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important"
  },
  button: {
    marginTop: 20,
    marginLeft: "25%",
    width: "50%",
    color: `${theme.palette.primary.main}`,
  }
});

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      comment: "",
      invalidEmail: true,
      snackBarOpen: false,
      snackBarVariant: "success",
      snackBarMessage: ""
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      this.validateField(name, value)
    );
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackBarOpen: false });
  };

  validateField = (fieldName, value) => {
    let emailValid = !this.state.invalidEmail;
    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        break;
      default:
        break;
    }
    this.setState({ invalidEmail: !emailValid });
  };

  sendEmail = () => {
    if (this.state.invalidEmail) {
      this.setState({
        snackBarMessage: "Please add a valid email!",
        snackBarVariant: "error",
        snackBarOpen: true
      });
    } else {
      axios
        .post("https://www.rour.company/contact", {
          name: this.state.name,
          email: this.state.email,
          comment: this.state.comment
        })
        .then(response => {
          this.setState({
            snackBarMessage: "Message sent!",
            snackBarVariant: "success",
            snackBarOpen: true
          });
        })
        .catch(error => {
          // console.log(error);
          this.setState({
            snackBarMessage: "There was an error. Email us at hi@rour.company",
            snackBarVariant: "error",
            snackBarOpen: true
          });
        });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div id="contact">
        <Paper className={classes.container}>
          <Typography variant="h4" className={classes.title}>
            Déjanos ayudarte!
          </Typography>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row" justify="center">
              <Grid
                container
                direction="column"
                item
                xs={12}
                sm={4}
                md={3}
                justify="center"
              >
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  name="name"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={this.state.name}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabelRoot,
                      focused: classes.inputLabelFocused,
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                      notchedOutline: classes.inputNotchedOutline
                    }
                  }}
                />
                <TextField
                  required
                  error={this.state.invalidEmail}
                  id="outlined-email-input"
                  label="Email"
                  className={classes.textField}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabelRoot,
                      focused: classes.inputLabelFocused,
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                      notchedOutline: classes.inputNotchedOutline
                    }
                  }}
                />
              </Grid>
              <Grid container item xs={12} sm={4} md={3} justify="center">
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Your Coments"
                  name="comment"
                  multiline
                  rows="5"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={this.state.comment}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabelRoot,
                      focused: classes.inputLabelFocused,
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.inputRoot,
                      focused: classes.inputFocused,
                      notchedOutline: classes.inputNotchedOutline
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.sendEmail}
            >
              Send
            </Button>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.snackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackBar
            onClose={this.handleClose}
            variant={this.state.snackBarVariant}
            message={this.state.snackBarMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Contact);
