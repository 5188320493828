import React from "react";
import ReactDOM from "react-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      lighter: "#4dabf5",
      light: "#4587bb",
      main: "#1769aa",
      dark: "#104976"
    },
    secondary: {
      light: "#f73378",
      main: "#f50057",
      darK: "#ab003c"
    }
  },
  typography: {
    useNextVariants: true
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
