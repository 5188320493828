import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const styles = theme => ({
  container: {
    padding: "20px",
    borderRadius: 0,
  },
  card: {
    paddingLeft: "20%",
    paddingRight: "20%",
    boxShadow: "None",
    textAlign: "center",

  },
  icon: {
      fontSize: "60px",
      color: theme.palette.primary.dark,
  }
});

class Services extends React.Component {

  render() {
    const { classes, data } = this.props;

    return (
      <div id="services">
        <Paper className={classes.container}>
          <Grid container justify="space-around">
            {data.map(service => (
              <Grid item xs={12} sm={6} md={4} key={service.title}>
                <Card className={classes.card}>
                  
                    <CardContent>
                      <Icon className={classes.icon}>{service.icon}</Icon>
                      <Typography gutterBottom variant="h5" component="h2">
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {service.content}
                      </Typography>
                    </CardContent>
                  
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
    );
  }
}

Services.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Services);
